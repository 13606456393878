










































































import Vue from "vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { get_color_for_percentage } from "@/utils/global";
export default Vue.extend({
  name: "AiEvaluationCard",
  data() {
    return {
      reason: ""
    };
  },
  props: {
    title: {
      type: String,
      default: "AI Evaluation"
    },
    ai_evaluation: {
      type: Object,
      default: () => ({
        coherence: 1,
        lexical_resource: 1,
        grammar: 1,
        accuracy: 1
      })
    }
  },
  mounted() {
    if (this.ai_evaluation.reason) {
      this.reason = this.ai_evaluation.reason;
    }
  },
  watch: {
    ai_evaluation: {
      handler() {
        if (this.ai_evaluation.reason) {
          this.reason = this.ai_evaluation.reason;
        }
      },
      deep: true
    }
  },
  computed: {
    assessment_result(): AppAssessments.AiEvaluationData[] {
      return Object.entries(this.ai_evaluation)
        .map(([title, score]) => {
          if (
            [
              "coherence",
              "lexical_resource",
              "grammar",
              "accuracy",
              "synonyms",
              "completeness",
              "relevance"
            ].includes(title)
          ) {
            return {
              title: this.translation(title),
              score: Number(score) as number
            };
          }
          return undefined;
        })
        .filter((item): item is AppAssessments.AiEvaluationData => !!item);
    }
  },
  methods: {
    get_color_for_percentage,
    translation(key: string) {
      const translations: Record<string, string> = {
        coherence: this.$t("assessments.coherance").toString(),
        lexical_resource: this.$t("assessments.lexical").toString(),
        grammar: this.$t("assessments.grammer").toString(),
        accuracy: this.$t("assessments.accuracy").toString(),
        completeness: this.$t("assessments.completeness").toString(),
        relevance: this.$t("assessments.relevance").toString()
      };
      return translations[key] || key;
    },
    get_percentage(score: number) {
      return (score / 5) * 100;
    }
  }
});
